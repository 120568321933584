/** @format */

import React from "react";

import { sAlgorithmIcon } from "./../extension/skillsImgs";

import "./../style/skills.css";

export const TenthSkill = () => {
  const handleYoutubeClick = () => {
    window.open("https://www.youtube.com/watch?v=go5TqWzAi-s", "_blank");
  };

  const handleGithubClick = () => {
    window.open("https://github.com/Abu-Nabe/Arithmetic-Sequence-Project", "_blank");
  };

  return (
    <div>
      <div className="skillsMainDiv">
        <img className="skillsImg" src={sAlgorithmIcon} alt="Image" />
        <p class="skillsName2">
          Availability: <span onClick={handleYoutubeClick}>Youtube</span> &{" "}
          <span onClick={handleGithubClick}>Github</span>
        </p>
        <p class="skillsName2">Languages: JavaScript</p>
        <p class="skillsName2">Purpose: Creating an algorithmic project</p>
        <p class="skillsName2">Frameworks: Visual Studio Code</p>

      </div>
    </div>
  );
};

export default TenthSkill;
