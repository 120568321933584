/** @format */
import React, { useEffect, useState } from "react";

import {resumeLink} from './../extension/about'

import './../style/resume.css'
function Resume() {
  useEffect(() => {
    document.title = "Abu Nabe - Résumé"; // Change the title here
  }, []);

  return (
    <div className="resumeDiv">
        <object data={resumeLink} type="application/pdf" width="100%" height="100%">

        </object>
    </div>
  );
}

export default Resume;
