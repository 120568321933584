/** @format */

import React from "react";

import { sCsharpIcon } from "./../extension/skillsImgs";

import "./../style/skills.css";

export const NinthSkill = () => {
  const handleYoutubeClick = () => {
    window.open("https://www.youtube.com/watch?v=dq2DXsxjTgI&feature=youtu.be", "_blank");
  };

  const handleGithubClick = () => {
    window.open("https://github.com/Abu-Nabe/CRUDOperationsCSharp", "_blank");
  };

  return (
    <div>
      <div className="skillsMainDiv">
        <img className="skillsImg" src={sCsharpIcon} alt="Image" />
        <p class="skillsName2">
          Availability: <span onClick={handleYoutubeClick}>Youtube</span> &{" "}
          <span onClick={handleGithubClick}>Github</span>
        </p>
        <p class="skillsName2">Languages: C#, JavaScript (React)</p>
        <p class="skillsName2">Database Management: MySql, Elastic, MySqlWorkBench</p>
        <p class="skillsName2">Frameworks: Visual Studio & Visual Studio Code</p>

      </div>
    </div>
  );
};

export default NinthSkill;
