export const aboutText = "Experienced software engineer with 4+ years of professional expertise. Currently I am working as a Software Engineer at State Trading Organization and am solely designing and developing my own startup, I have also successfully gained business partnerships by leveraging my expertise as a software engineer to solve their technical problems. Additionally, I've completed multiple freelance projects, spanning full-stack development to mobile development. I also provide programming tutoring. My commitment to delivering high-quality solutions is complemented by a continuous pursuit of expanding my skill set."

export const socirankText = "SociRank is an innovative platform designed to showcase talent and help individuals elevate their social status. Our mission is to bring to light the hidden gems in society, those individuals who possess exceptional talent but have yet to receive the recognition they deserve. We believe that every person has the potential to be a star in their own right, and our goal is to provide a platform for these individuals to shine. At SociRank, we are dedicated to helping people discover their true potential and reach new heights in their personal and professional lives. Whether you’re a musician, artist, athlete, or any other type of talented individual, SociRank is the perfect platform to help you showcase your skills and connect with others in your field.";
export const socirankWebText = "The SociRank Website was initially designed to mirror the mobile app, but this approach proved to be an unfavorable business decision. To shift our focus towards revenue generation, the website was adapted to enable users to both sell their items and purchase items from other users using SociCoins. The primary objective evolved into generating revenue.";
export const bankText = "Facing challenges due to country-specific limitations, the consumer encountered difficulties when using PayPal for transaction processing. To address this issue, I proposed a creative solution: establishing a personalized banking system. This system would empower the user to initiate and monitor transactions using APIs tailored to their specific needs.";
export const foodText = "My client was concerned about food wastage in their local area and was keen on finding a solution. They entrusted me with the task of developing an app that effectively tracks food expenses by scanning barcodes and decoding the information. The project proved to be a significant success, meeting all requirements seamlessly.";
export const collegeText = "The educational-based app was designed specifically for a college institution with the aim of simplifying the scheduling and attendance recording processes. While I may not have had direct knowledge of the reasons for creating the app, I assume it was developed to replace the college’s existing app. Although I wasn’t involved in the frontend development, I’m satisfied with my contribution to the backend.";
export const tutorText = "Our e-commerce website was created as part of my tutoring lessons, with students who had no prior programming experience. It was a challenging yet rewarding experience for all of us, as we navigated through the complexities of web development and e-commerce functionality.";
export const tutorText2 = "In this second tutoring project, I've witnessed a remarkable growth in my student's programming abilities. They have reached a point where they can independently create basic websites, underscoring the valuable progress made during our lessons. Additionally, we collaborated to design a game shop, which further exemplifies their newfound skills and creativity in web development.";
export const hobbystarText = "My inaugural high school startup, which was developed three years ago, aimed to facilitate seamless connections among individuals who shared common passions. Our platform enabled users to not only find like-minded enthusiasts but also showcase their shared interests collaboratively."
export const pelegantText= "Secured a equity partnership with Kashmir Arts Emporium, a prominent company with a widespread presence across Asia. By leading the seamless transition of their business to an online platform, I not only expanded consumer access to their goods worldwide but also significantly enhanced operational efficiency. The strategic selection of cloud computing services played a pivotal role in this achievement, marking a significant milestone for both the company and my role as a Software Engineer."
export const aagroupText = "Established a strategic promotional partnership by identifying operational inefficiencies within a business and proactively engaging with the business owner for collaborative improvement. Implemented transformative enhancements, including the automation of processes through a customized chat system for user support and the integration of a booking feature. This innovative approach, replacing the traditional phone call for all inquiries, significantly elevated customer satisfaction, expanded service offerings, and notably increased customer retention."

export const resumeLink = "https://firebasestorage.googleapis.com/v0/b/project-326605.appspot.com/o/resumes%2Fonline_resume.pdf?alt=media&token=f4519727-e5d4-4674-b177-17b43abef665"